import React, { useState } from 'react'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import Layout from '../../components/layout/index'
import Head from '../../components/head/index'
import { LoginModal, OrderDetailsContainer } from 'tf-checkout-react'
import Logo from '../../assets/images/mana_footer.png'
import './style.scss'
import { X_TF_ECOMMERCE } from '../../components/header'
import { getCookieByName } from '../../utils/cookies'

setTfCheckoutReactConfigs()

const OrderDetails = () => {
  const isWindowDefined = typeof window !== 'undefined'
  const [isLogged, setIsLogged] = useState(
    isWindowDefined ? !!getCookieByName(X_TF_ECOMMERCE) : false
  )

  const handleOnLogin = (e) => {
    setIsLogged(true)
  }

  return (
    <>
      <Head title="Order Details" />
      <Layout>
        {isLogged ? (
          <OrderDetailsContainer />
        ) : (
          <LoginModal logo={Logo} onLogin={handleOnLogin} />
        )}
      </Layout>
    </>
  )
}

export default OrderDetails
